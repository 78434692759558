import Chart from 'chart.js';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { ApiValveHistoryItem } from '../../../models/openapi/openapiTypes';
interface Props {
  data: ApiValveHistoryItem[];
  pressureUnit?: string;
  tempUnit?: string;
  filterMod?: number;
}

const HistoryLineChart: React.FC<Props> = ({
  data,
  pressureUnit,
  tempUnit,
  filterMod,
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState(undefined as Chart | undefined);

  useLayoutEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const ctx = chartRef.current.getContext('2d');
    Chart.defaults.global.defaultFontColor = '#969696';

    if (!ctx) {
      return;
    }

    const pressureData = data
      .map(({ timestamp, pressureBar }) => ({
        t: timestamp,
        y: pressureBar,
      }))
      .filter((value, index) => index % (filterMod ?? 1) === 0);

    const tempData = data
      .map(({ timestamp, temperatureManometerCelsius }) => ({
        t: timestamp,
        y: temperatureManometerCelsius,
      }))
      .filter((value, index) => index % (filterMod ?? 1) === 0);

    if (!chart) {
      setChart(
        new Chart(ctx, {
          data: {
            datasets: [
              {
                data: pressureData,
                type: 'line',
                yAxisID: 'pressure',
                xAxisID: 'time',
                label: 'Druck (bar)',
                borderColor: '#009fe3',
                borderWidth: 2,
                fill: false,
                lineTension: 0,
                spanGaps: true,
                pointRadius: 0,
                pointHitRadius: 10,
                pointHoverRadius: 16,
                pointHoverBorderColor: 'transparent',
              },
              {
                data: tempData,
                type: 'line',
                yAxisID: 'temp',
                xAxisID: 'time',
                label: 'Temperatur (°C)',
                borderColor: '#da0025',
                borderWidth: 2,
                fill: false,
                lineTension: 0,
                spanGaps: true,
                pointRadius: 0,
                pointHitRadius: 10,
                pointHoverRadius: 16,
                pointHoverBorderColor: 'transparent',
              },
            ],
          },
          options: {
            scales: {
              xAxes: [
                {
                  type: 'time',
                  distribution: 'series',
                  bounds: 'data',
                  id: 'time',
                  time: {
                    tooltipFormat: 'DD.MM.YY HH:mm',
                    displayFormats: {
                      millisecond: 'HH:mm [Uhr]',
                      second: 'HH:mm [Uhr]',
                      minute: 'HH:mm [Uhr]',
                      hour: 'DD.MM, HH [Uhr]',
                      day: 'DD.MM.YY',
                      week: 'DD.MM.YY',
                      month: 'MM.YYYY',
                      quarter: 'MM.YYYY',
                      year: 'MM.YYYY',
                    },
                  },
                  ticks: {
                    major: {
                      enabled: true,
                      fontStyle: 'bold',
                    },
                    source: 'data',
                    autoSkip: true,
                    autoSkipPadding: 20,
                    maxRotation: 90,
                    sampleSize: 100,
                  },
                  gridLines: {
                    color: 'rgba(0, 49, 105, 0.2)',
                    drawBorder: true,
                    drawOnChartArea: false,
                  },
                },
              ],
              yAxes: [
                {
                  type: 'linear',
                  id: 'pressure',
                  gridLines: {
                    color: 'rgba(0, 49, 105, 0.2)',
                    drawBorder: true,
                    drawOnChartArea: false,
                  },
                  ticks: {
                    callback(
                      value: number | string,
                      index: number,
                      values: number[] | string[]
                    ): string | number | null | undefined {
                      return `${value} ${pressureUnit}`;
                    },
                    suggestedMin: 0,
                    suggestedMax: 320,
                    stepSize: 40,
                  },
                },
                {
                  type: 'linear',
                  id: 'temp',
                  position: 'right',
                  gridLines: {
                    color: 'rgba(0, 49, 105, 0.2)',
                    drawBorder: true,
                    drawOnChartArea: false,
                  },
                  ticks: {
                    callback(
                      value: number | string,
                      index: number,
                      values: number[] | string[]
                    ): string | number | null | undefined {
                      return `${value} ${tempUnit}`;
                    },
                    suggestedMin: -10,
                    suggestedMax: 30,
                  },
                },
              ],
            },
            legend: {
              display: true,
              position: 'top',
              align: 'end',
            },
            tooltips: {
              backgroundColor: '#000',
              titleFontSize: 12,
              bodyFontSize: 12,
              cornerRadius: 0,
              displayColors: false,
              xPadding: 10,
              yPadding: 10,
              callbacks: {
                label(
                  tooltipItem: Chart.ChartTooltipItem,
                  data: Chart.ChartData
                ): string | string[] {
                  return `${tooltipItem.value} ${
                    tooltipItem.datasetIndex === 0 ? pressureUnit : tempUnit
                  }`;
                },
              },
            },
            animation: {
              duration: 0,
            },
            // responsive: true,
            maintainAspectRatio: false,
          },
        })
      );
    } else {
      chart.data.datasets![0].data = pressureData;
      chart.data.datasets![1].data = tempData;
      chart.update();
    }
  }, [data, chart, setChart, pressureUnit, tempUnit, filterMod]);

  return <canvas id="chart" ref={chartRef} width="100%" height="100%" />;
};

export default HistoryLineChart;
