import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { ManometerPairing } from '../../../stories/ManometerPairing';
import * as inventoryActions from '../../inventory/actions';
import { ManualContainerFillLevel } from '../../inventory/models/container-state';
import * as inventorySelectors from '../../inventory/selectors';
import * as digidrumiActions from '../actions';
import * as commonSelectors from '../../common/selectors';
import * as digidrumiSelectors from '../selectors';
import {
  ApiGaasAction,
  ApiContainerValvePairing,
} from '../../../models/openapi/openapiTypes';

type BottleDropdownOption = {
  barcode: string;
  gasDescription: string;
  fillLevel: number | ManualContainerFillLevel;
};

const mapStateToProps = (state: RootState) => ({
  inventory: inventorySelectors.inventory(state.inventory),
  containerUnpairingInProgress: (deviceIdentifier: string) =>
    digidrumiSelectors.containerUnpairingInProgress(
      state.digidrumi,
      deviceIdentifier
    ),
  containerPairingInProgress: (deviceIdentifier: string) =>
    digidrumiSelectors.containerPairingInProgress(
      state.digidrumi,
      deviceIdentifier
    ),
  hasPermission: (action: ApiGaasAction) =>
    commonSelectors.hasPermission(action, state.common),
});

const dispatchProps = {
  getInventory: inventoryActions.getInventoryAsync.request,
  pairContainerToDevice: digidrumiActions.pairContainerToValveAsync.request,
  unpairContainerFromDevice:
    digidrumiActions.unpairContainerFromValveAsync.request,
};

type ComponentProps = {
  customerId: string;
  serialNumber: string;
  currentPairing?: ApiContainerValvePairing;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const DigiDrumiPairing: React.FC<Props> = ({
  inventory,
  getInventory,
  pairContainerToDevice,
  unpairContainerFromDevice,
  serialNumber,
  customerId,
  currentPairing,
  containerUnpairingInProgress,
  containerPairingInProgress,
  hasPermission,
}) => {
  const [selectedBottleBarcode, setSelectedBottleBarcode] = useState<
    string | null
  >(null);

  const unpairingInProgress = containerUnpairingInProgress(serialNumber);

  const sortedStockContainers = useMemo<BottleDropdownOption[] | undefined>(
    () =>
      inventory?.data?.containers
        ?.map(it => ({
          barcode: it.barcode,
          gasDescription: it.materialDescription,
          fillLevel: it.fillLevel,
        }))
        ?.sort((a, b) => a.barcode.localeCompare(b.barcode))
        ?.sort((a, b) => a.gasDescription.localeCompare(b.gasDescription)),
    [inventory]
  );

  return (
    <ManometerPairing
      customerId={customerId}
      serialNumber={serialNumber}
      currentPairing={currentPairing}
      onAutocompleteOpen={() => {
        if (inventory.data == null && !inventory.loading) {
          getInventory(customerId);
        }
      }}
      autocompleteLoading={inventory.loading}
      dropdownOptions={sortedStockContainers}
      onSelect={setSelectedBottleBarcode}
      pairingDisabled={selectedBottleBarcode == null}
      onPair={() =>
        pairContainerToDevice({
          serialNumber,
          barcode: selectedBottleBarcode!,
          pairingType: 'manual',
        })
      }
      onUnpair={() => unpairContainerFromDevice({ serialNumber })}
      unpairingInProgress={unpairingInProgress}
      permission={hasPermission('meter.pairing.write')}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(DigiDrumiPairing);
