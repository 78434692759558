import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import React from 'react';
import ShopCustomerNumberProvider from '../components/ShopCustomerNumberProvider';
import ShopSubscriptionCreateOrUpdateGuard from '../components/ShopSubscriptionCreateOrUpdateGuard';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const ShopPage: React.FC<Props> = () => {
  return (
    <ShopCustomerNumberProvider>
      <ShopSubscriptionCreateOrUpdateGuard />
    </ShopCustomerNumberProvider>
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopPage);
