import React, { FC, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ArrowForward } from '../../../stories/icons';

interface ComponentProps {
  groupId: string;
  groupName: string;
  customerId: string;
}

const DigiDrumiGroupAccordion: FC<ComponentProps> = ({
  children,
  groupId,
  groupName,
  customerId,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [initiallyExpanded, setInitiallyExpanded] = useState<
    boolean | undefined
  >(undefined);
  const localStorageKey = `GAAS-GROUP-ACCORDION-${customerId}-${groupId}`;

  useEffect(() => {
    const storedValue =
      (localStorage.getItem(localStorageKey) ?? 'true') === 'true';
    setExpanded(storedValue);
    setInitiallyExpanded(storedValue);
  }, [localStorageKey, setExpanded, setInitiallyExpanded]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, expanded.toString());
  }, [localStorageKey, expanded]);

  if (initiallyExpanded === undefined) {
    return null;
  }

  return (
    <Accordion
      defaultExpanded={initiallyExpanded}
      disableGutters={true}
      sx={{
        '&:before': { display: 'none' },
        background: 'transparent',
        boxShadow: 'none',
        borderBottom: 1,
        borderColor: '#d0d0d0',
      }}
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        >
          <ArrowForward
            style={{
              width: '1lh',
              transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              marginRight: '0.5rem',
            }}
          />
          <h1>{groupName}</h1>
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default DigiDrumiGroupAccordion;
