import React, { useEffect, useState } from 'react';
import styles from './PlansChip.module.scss';
import classNames from 'classnames';
import { Lock, Subscription } from './icons';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  permission: PermissionQueryResponse;
  top?: number | string;
  right?: number | string;
  onClick?: () => void;
}

export const PlansChip: React.FC<Props> = ({
  permission,
  onClick,
  top,
  right,
}) => {
  const [label, setLabel] = useState('');

  useEffect(() => {
    if (permission[1].requiredSubscription) {
      switch (permission[1].requiredSubscription) {
        case 'subscription-transparency':
          setLabel('Transparency');
          break;
      }
    } else if (permission[1].requiredRole) {
      switch (permission[1].requiredRole) {
        case 'role-basic':
          setLabel('Basic');
          break;
        case 'role-premium':
          setLabel('Premium');
          break;
        case 'role-admin':
          setLabel('Admin');
          break;
      }
    } else {
      setLabel('No Plan');
    }
  }, [permission]);

  const Content = () => (
    <div
      className={classNames(styles['gaas-plansChip'], {})}
      style={{ top: top, right: right }}
      onClick={onClick}
    >
      {permission[0] === 'DENIED_ROLE' ? (
        <Lock className={styles['gaas-plansChip--icon']} />
      ) : (
        <Subscription className={styles['gaas-plansChip--icon']} />
      )}
      <div className={styles['gaas-plansChip--label']}>{label}</div>
    </div>
  );
  return <Content />;
};
