import { createReducer } from 'typesafe-actions';
import {
  ApiContainerGroupAssignment,
  ApiStockContainer,
} from '../../models/openapi/openapiTypes';
import {
  AsyncResource,
  asyncResourceFailure,
  asyncResourceRequest,
  asyncResourceSuccess,
} from '../../store/async-resource';
import { signOutAsync } from '../common/actions';
import {
  changeContainerGroupAsync,
  createInventoryThresholdAsync,
  deleteInventoryThresholdAsync,
  getContainerNoteAsync,
  getContainerStateByBarcodeAsync,
  getInventoryAsync,
  getStockContainerAsync,
  listActiveInventoryThresholdEventsAsync,
  listInventoryThresholdsAsync,
  returnContainerAsync,
  takeContainerAsync,
  updateContainerNoteAsync,
  updateInventoryThresholdAsync,
} from './actions';
import { ContainerState } from './models/container-state';
import { Inventory } from './models/inventory';
import { InventoryThresholdPassedEventView } from './models/inventory-threshold-passed-event-view';
import { InventoryThresholdView } from './models/inventory-threshold-view';

interface State {
  inventory?: AsyncResource<Inventory>;
  stockContainer?: AsyncResource<ApiStockContainer[]>;
  bottleLoading: { [bottleId: string]: AsyncResource<ContainerState> };
  bottleGroupAssignmentLoading: {
    [bottleId: string]: AsyncResource<ApiContainerGroupAssignment>;
  };
  inventoryThresholds: {
    [customerId: string]: AsyncResource<InventoryThresholdView[]>;
  };
  inventoryThresholdPassedEvents: {
    [customerId: string]: AsyncResource<InventoryThresholdPassedEventView[]>;
  };
  container: { [barcode: string]: AsyncResource<ContainerState> };
  containerNotes: { [containerId: string]: AsyncResource<string> };
}

const initialState: State = {
  bottleLoading: {},
  inventoryThresholds: {},
  inventoryThresholdPassedEvents: {},
  container: {},
  containerNotes: {},
} as State;

export const inventoryReducer = createReducer(initialState)
  .handleAction(getInventoryAsync.request, state => ({
    ...state,
    inventory: asyncResourceRequest(),
  }))
  .handleAction(getInventoryAsync.success, (state, { payload: inventory }) => ({
    ...state,
    inventory: asyncResourceSuccess(inventory),
  }))
  .handleAction(getInventoryAsync.failure, (state, { payload: error }) => ({
    ...state,
    inventory: asyncResourceFailure(error),
  }))
  .handleAction(getStockContainerAsync.request, state => ({
    ...state,
    stockContainer: asyncResourceRequest(),
  }))
  .handleAction(
    getStockContainerAsync.success,
    (state, { payload: container }) => ({
      ...state,
      stockContainer: asyncResourceSuccess(container),
    })
  )
  .handleAction(
    getStockContainerAsync.failure,
    (state, { payload: error }) => ({
      ...state,
      stockContainer: asyncResourceFailure(error),
    })
  )
  .handleAction(
    getContainerStateByBarcodeAsync.request,
    (state, { payload }) => ({
      ...state,
      container: { ...state.container, [payload]: asyncResourceRequest() },
    })
  )
  .handleAction(
    getContainerStateByBarcodeAsync.success,
    (state, { payload: { barcode, container } }) => ({
      ...state,
      container: {
        ...state.container,
        [barcode]: asyncResourceSuccess(container),
      },
    })
  )
  .handleAction(
    getContainerStateByBarcodeAsync.failure,
    (state, { payload: { error, barcode } }) => ({
      ...state,
      container: { ...state.container, [barcode]: asyncResourceFailure(error) },
    })
  )
  .handleAction(
    takeContainerAsync.request,
    (state, { payload: { bottleId } }) => {
      return {
        ...state,
        bottleLoading: {
          ...state.bottleLoading,
          [bottleId]: asyncResourceRequest(),
        },
      };
    }
  )
  .handleAction(
    takeContainerAsync.success,
    (state, { payload: { container, groupId } }) => {
      const oldInventory = state.inventory?.data;
      const realContainer = { ...container, groupId };
      if (oldInventory == null) {
        return {
          ...state,
          bottleLoading: {
            ...state.bottleLoading,
            [container.id]: asyncResourceSuccess(container),
          },
        };
      }
      const idx = oldInventory.containers.findIndex(
        it => it.id === container.id
      );
      oldInventory.containers[idx] = realContainer;
      return {
        ...state,
        inventory: asyncResourceSuccess({ ...oldInventory }),
        bottleLoading: {
          ...state.bottleLoading,
          [container.id]: asyncResourceSuccess(container),
        },
      };
    }
  )
  .handleAction(
    takeContainerAsync.failure,
    (state, { payload: { error, bottleId } }) => {
      return {
        ...state,
        bottleLoading: {
          ...state.bottleLoading,
          [bottleId]: asyncResourceFailure(error),
        },
      };
    }
  )
  .handleAction(
    returnContainerAsync.request,
    (state, { payload: { bottleId } }) => {
      return {
        ...state,
        bottleLoading: {
          ...state.bottleLoading,
          [bottleId]: asyncResourceRequest(),
        },
      };
    }
  )
  .handleAction(
    returnContainerAsync.success,
    (state, { payload: container }) => {
      const oldInventory = state.inventory?.data;
      if (oldInventory == null) {
        return {
          ...state,
          bottleLoading: {
            ...state.bottleLoading,
            [container.id]: asyncResourceSuccess(container),
          },
        };
      }
      const idx = oldInventory.containers.findIndex(
        it => it.id === container.id
      );
      oldInventory.containers[idx] = {
        ...container,
        groupId: null,
        note: oldInventory.containers[idx].note,
      };
      return {
        ...state,
        inventory: asyncResourceSuccess({ ...oldInventory }),
        bottleLoading: {
          ...state.bottleLoading,
          [container.id]: asyncResourceSuccess(container),
        },
      };
    }
  )
  .handleAction(
    returnContainerAsync.failure,
    (state, { payload: { error, bottleId } }) => {
      return {
        ...state,
        bottleLoading: {
          ...state.bottleLoading,
          [bottleId]: asyncResourceFailure(error),
        },
      };
    }
  )
  .handleAction(
    listInventoryThresholdsAsync.request,
    (state, { payload: { customerId } }) => {
      return {
        ...state,
        inventoryThresholds: {
          ...state.inventoryThresholds,
          [customerId]: asyncResourceRequest(),
        },
      };
    }
  )
  .handleAction(
    listInventoryThresholdsAsync.success,
    (state, { payload: { customerId, inventoryThresholds } }) => {
      return {
        ...state,
        inventoryThresholds: {
          ...state.inventoryThresholds,
          [customerId]: asyncResourceSuccess(inventoryThresholds),
        },
      };
    }
  )
  .handleAction(
    listInventoryThresholdsAsync.failure,
    (state, { payload: { customerId, error } }) => {
      return {
        ...state,
        inventoryThresholds: {
          ...state.inventoryThresholds,
          [customerId]: asyncResourceFailure(error),
        },
      };
    }
  )
  .handleAction(
    listActiveInventoryThresholdEventsAsync.request,
    (state, { payload: { customerId } }) => {
      return {
        ...state,
        inventoryThresholdPassedEvents: {
          ...state.inventoryThresholdPassedEvents,
          [customerId]: asyncResourceRequest(),
        },
      };
    }
  )
  .handleAction(
    listActiveInventoryThresholdEventsAsync.success,
    (state, { payload: { customerId, events } }) => {
      return {
        ...state,
        inventoryThresholdPassedEvents: {
          ...state.inventoryThresholdPassedEvents,
          [customerId]: asyncResourceSuccess(events),
        },
      };
    }
  )
  .handleAction(
    listActiveInventoryThresholdEventsAsync.failure,
    (state, { payload: { customerId, error } }) => {
      return {
        ...state,
        inventoryThresholdPassedEvents: {
          ...state.inventoryThresholdPassedEvents,
          [customerId]: asyncResourceFailure(error),
        },
      };
    }
  )
  .handleAction(
    updateInventoryThresholdAsync.request,
    (state, { payload: { threshold, customerId } }) => {
      const newState = { ...state };
      const newThresholds = (
        newState.inventoryThresholds[customerId]?.data ?? []
      ).filter(t => t.id !== threshold.id);
      newThresholds.push(threshold);
      newState.inventoryThresholds[customerId] =
        asyncResourceSuccess(newThresholds);
      return newState;
    }
  )
  .handleAction(createInventoryThresholdAsync.request, (state, { payload }) => {
    return {
      ...state,
      inventoryThresholds: {
        ...state.inventoryThresholds,
        [payload.customerId]: asyncResourceSuccess([
          ...(state.inventoryThresholds[payload.customerId]?.data ?? []),
          payload,
        ]),
      },
    };
  })
  .handleAction(
    createInventoryThresholdAsync.failure,
    (state, { payload: { error, inventoryThreshold } }) => {
      return {
        ...state,
        // todo: log/display error
        inventoryThresholds: {
          ...state.inventoryThresholds,
          [inventoryThreshold.customerId]: asyncResourceSuccess(
            (
              state.inventoryThresholds[inventoryThreshold.customerId]?.data ??
              []
            ).filter(threshold => threshold.id !== inventoryThreshold.id)
          ),
        },
      };
    }
  )
  .handleAction(deleteInventoryThresholdAsync.request, (state, { payload }) => {
    const newThresholds = (
      state.inventoryThresholds[payload.customerId].data ?? []
    ).filter(
      threshold =>
        threshold.id !== payload.id ||
        threshold.customerId !== payload.customerId ||
        threshold.materialId !== payload.materialId
    );
    return {
      ...state,
      inventoryThresholds: {
        ...state.inventoryThresholds,
        [payload.customerId]: asyncResourceSuccess(newThresholds),
      },
    };
  })
  .handleAction(
    deleteInventoryThresholdAsync.failure,
    (state, { payload: { error, inventoryThreshold } }) => {
      return {
        ...state,
        inventoryThresholds: {
          ...state.inventoryThresholds,
          [inventoryThreshold.customerId]: asyncResourceSuccess([
            ...(state.inventoryThresholds[inventoryThreshold.customerId]
              ?.data ?? []),
            inventoryThreshold,
          ]),
        },
      };
    }
  )
  .handleAction(signOutAsync.success, () => ({
    ...initialState,
  }))
  .handleAction(
    getContainerNoteAsync.request,
    (state, { payload: { containerId } }) => ({
      ...state,
      containerNotes: {
        ...state.containerNotes,
        [containerId]: asyncResourceRequest(),
      },
    })
  )
  .handleAction(
    getContainerNoteAsync.success,
    (state, { payload: { containerId, containerNote } }) => ({
      ...state,
      containerNotes: {
        ...state.containerNotes,
        [containerId]: asyncResourceSuccess(
          containerNote ? containerNote.content : ''
        ),
      },
    })
  )
  .handleAction(
    getContainerNoteAsync.failure,
    (state, { payload: { containerId, error } }) => ({
      ...state,
      containerNotes: {
        ...state.containerNotes,
        [containerId]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(
    updateContainerNoteAsync.request,
    (state, { payload: { containerId, content } }) => ({
      ...state,
      containerNotes: {
        ...state.containerNotes,
        [containerId]: asyncResourceSuccess(content),
      },
    })
  )
  .handleAction(
    updateContainerNoteAsync.failure,
    (state, { payload: { containerId, error } }) => ({
      ...state,
      containerNotes: {
        ...state.containerNotes,
        [containerId]: asyncResourceFailure(error),
      },
    })
  )
  .handleAction(
    changeContainerGroupAsync.request,
    (state, { payload: { containerId } }) => {
      return {
        ...state,
        bottleGroupAssignmentLoading: {
          ...state.bottleGroupAssignmentLoading,
          [containerId]: asyncResourceRequest(),
        },
      };
    }
  )
  .handleAction(
    changeContainerGroupAsync.success,
    (state, { payload: { containerId, newAssignment } }) => {
      const oldInventory = state.inventory?.data;
      if (oldInventory == null) {
        return {
          ...state,
          bottleGroupAssignmentLoading: {
            ...state.bottleGroupAssignmentLoading,
            [containerId]: asyncResourceSuccess(newAssignment),
          },
        };
      }
      const idx = oldInventory.containers.findIndex(
        it => it.id === containerId
      );
      oldInventory.containers[idx] = {
        ...oldInventory.containers[idx],
        groupId: newAssignment.groupId,
      };
      return {
        ...state,
        inventory: asyncResourceSuccess({ ...oldInventory }),
        bottleGroupAssignmentLoading: {
          ...state.bottleGroupAssignmentLoading,
          [containerId]: asyncResourceSuccess(newAssignment),
        },
      };
    }
  )
  .handleAction(
    changeContainerGroupAsync.failure,
    (state, { payload: { containerId, error } }) => {
      return {
        ...state,
        bottleLoading: {
          ...state.bottleLoading,
          [containerId]: asyncResourceFailure(error),
        },
      };
    }
  );

export default inventoryReducer;
export type InventoryState = ReturnType<typeof inventoryReducer>;
