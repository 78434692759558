import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import { BarcodeScannerModal } from '../../../stories/BarcodeScannerModal';
import { Loading } from '../../../stories/Loading';
import { TextButton } from '../../../stories/TextButton';
import { ArrowBack, ErrorFilled, SearchOff } from '../../../stories/icons';
import * as inventoryActions from '../../inventory/actions';
import * as inventorySelectors from '../../inventory/selectors';
import * as digidrumiActions from '../actions';
import * as digidrumiSelectors from '../selectors';
import styles from './DigiDrumiBarcodePairingPage.module.scss';
import { BarcodeScannerV2 } from '../../../components/barcode-scanner-v2/BarcodeScannerV2';

const mapStateToProps = (state: RootState) => ({
  containerPairingInProgress: (serialNumber: string) =>
    digidrumiSelectors.containerPairingInProgress(
      state.digidrumi,
      serialNumber
    ),
  inventory: inventorySelectors.inventory(state.inventory),
  valves: digidrumiSelectors.valves(state.digidrumi),
});

const dispatchProps = {
  pairContainerToDevice: digidrumiActions.pairContainerToValveAsync.request,
  getInventory: inventoryActions.getInventoryAsync.request,
  getValves: digidrumiActions.getValvesAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

type RouteParams = {
  serialNumber: string;
  customer: string;
};

const DigiDrumiBarcodePairing: React.FC<Props> = ({
  containerPairingInProgress,
  getInventory,
  inventory,
  pairContainerToDevice,
  valves,
  getValves,
}) => {
  const history = useHistory();
  const { serialNumber, customer } = useParams<RouteParams>();
  const [requestedBarcode, setRequestedBarcode] = useState<string | null>(null);
  const returnLinkRef = useRef<HTMLAnchorElement>(null);

  const [unknownBarcode, setUnknownBarcode] = useState(false);

  useEffect(() => {
    if (inventory.data == null && !inventory.loading && customer != null) {
      getInventory(customer);
    }
  }, [customer, inventory, getInventory]);

  useEffect(() => {
    if (valves.data == null && !valves.loading && serialNumber != null) {
      getValves(customer);
    }
  }, [customer, valves, getValves, serialNumber]);

  const deviceIndex = (valves.data ?? []).findIndex(
    valve => valve.serialNumber === serialNumber
  );
  const currentBarcode =
    deviceIndex !== -1
      ? valves.data![deviceIndex].containerPairing?.container?.barcode
      : null;

  // this effect triggers the return link once (as soon as a valid barcode is scanned)
  useEffect(() => {
    if (
      currentBarcode === requestedBarcode &&
      requestedBarcode != null &&
      returnLinkRef.current
    ) {
      returnLinkRef.current.click();
    }
  }, [currentBarcode, requestedBarcode, returnLinkRef]);

  const onBarcodeFound = (barcode: string) => {
    if (containerPairingInProgress(serialNumber)) {
      return;
    }
    if (!serialNumber) {
      console.log('error, unknown serialnumber');
      return;
    }
    if (
      !inventory.data!.containers.some(
        container => container.barcode === barcode
      )
    ) {
      setUnknownBarcode(true);
      console.log('error, unknown barcode');
      return;
    }
    setRequestedBarcode(barcode);
    pairContainerToDevice({
      barcode,
      pairingType: 'barcode',
      serialNumber,
    });
  };

  const deviceNotFound = () => {
    return (
      <div className={styles['gaas-pairing-page--container']}>
        <SearchOff width="40px" />
        <p>GaaS Meter konnte nicht gefunden werden.</p>
        <TextButton
          label="Zurück"
          LeadingIcon={ArrowBack}
          onClick={() => history.push(`/digidrumi/${customer}/${serialNumber}`)}
        />
      </div>
    );
  };

  const content = () => {
    if (!serialNumber || !customer) {
      return deviceNotFound();
    }

    if (
      inventory.data == null ||
      inventory.loading ||
      valves.data == null ||
      valves.loading
    ) {
      return (
        <div className={styles['gaas-pairing-page--container']}>
          <Loading />
          <p>Kamera wird gestartet…</p>
        </div>
      );
    }

    if (containerPairingInProgress(serialNumber)) {
      return (
        <div className={styles['gaas-pairing-page--container']}>
          <Loading />
          <p>Kopplung…</p>
        </div>
      );
    }

    if (unknownBarcode) {
      return (
        <div className={styles['gaas-pairing-page--container']}>
          <ErrorFilled />
          <p>
            Diese Flasche befindet sich nicht im Bestand und kann daher nicht
            gekoppelt werden.
          </p>
        </div>
      );
    }

    if (deviceIndex === -1) {
      return deviceNotFound();
    }

    if (
      valves.data![deviceIndex].containerPairing?.container?.barcode ===
      requestedBarcode
    ) {
      return (
        <Link
          ref={returnLinkRef}
          to={`/digidrumi/${customer}/${serialNumber}`}
        />
      );
    }

    return <BarcodeScannerV2 onBarcodeFound={onBarcodeFound} />;
  };

  return (
    <BarcodeScannerModal onClose={() => history.push(`/digidrumi`)}>
      {content()}
    </BarcodeScannerModal>
  );
};

export default connect(mapStateToProps, dispatchProps)(DigiDrumiBarcodePairing);
