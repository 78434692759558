import { createContext, useContext } from 'react';
import { GaasSubscription } from '../../../models/openapi/openapiTypes';

export const ShopCustomerNumberContext = createContext<{
  customerNo: string;
  existingSubscription?: GaasSubscription;
}>({ customerNo: 'none' });

export const useShopCustomerNumber = () =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useContext(ShopCustomerNumberContext)!;
