import React, { useState } from 'react';
import styles from './ShopWizard.module.scss';
import { TextButton } from '../../../stories/TextButton';
import { Info } from '../../../stories/icons';
import { InfoBox } from '../../../stories/InfoBox';
import ShopItemInput from './ShopItemInput';
import { ApiGetShopItemsResponse } from '../../../models/openapi/openapiTypes';
import * as shopActions from '../actions';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';

interface ComponentProps {
  shopItemEntries: ApiGetShopItemsResponse;
  billingAddressId: string;
}

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {
  changeItemQuantity: shopActions.currentCartChangeItemQuantity,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopConfiguratorStepUser: React.FC<Props> = ({
  shopItemEntries,
  changeItemQuantity,
  billingAddressId,
}) => {
  const [infoVisible, setInfoVisible] = useState<boolean>(false);

  const users = shopItemEntries.filter(it => it.category === 'user');

  return (
    <>
      <div className={styles['gaas-shopPage--content--selection--info']}>
        <span>Wählen Sie die Anzahl Nutzer aus:</span>
        <TextButton
          LeadingIcon={Info}
          onClick={() => setInfoVisible(!infoVisible)}
          inline
        />
      </div>
      {infoVisible && (
        <InfoBox>
          <span>
            Sie können Ihre BASIC- und PREMIUM-User-Lizenzen nach Bedarf erhöhen
            oder reduzieren. Nach Abschluss des Vertrages wird die Anzahl
            Lizenzen umgehend geändert, die angepasste Abrechnung beginnt im
            darauf folgenden Monat.
          </span>
        </InfoBox>
      )}
      <div className={styles['gaas-shopPage--content--selection--user']}>
        {users.map(userItem => (
          <div
            className={styles['gaas-shopPage--content--selection--input']}
            key={userItem.itemId}
          >
            <ShopItemInput
              key={userItem.itemId}
              itemId={userItem.itemId}
              itemName={userItem.name}
              onItemQuantityChanged={(itemId: string, quantity: number) =>
                changeItemQuantity({
                  itemId,
                  quantity,
                  shippingAddressId: billingAddressId,

                  // user don't need to be sorted,
                  // so we don't care about the addedTs
                  addedTs: Date.now(),
                  // as shipping addresses dont apply to users
                  // and this can only exist once in a cart
                  // we can hardcorde the unique cart id
                  id: itemId,
                })
              }
            />
            <span
              className={
                styles['gaas-shopPage--content--selection--input--label']
              }
            >
              Pro Nutzer {userItem.price} EUR / Mon.
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ShopConfiguratorStepUser);
