import { WsStreamingValveUpdatePayloadType } from '../../models/zod/WsSchema';
import { ValveHistoryBucket } from '../../services/valve-service';
import { makeAsyncAction } from '../../util/makeAsyncAction';
import {
  ApiValveHistoryItem,
  ApiContainerValvePairingType,
  ApiContainerValvePairing,
  ApiPressureThresholdView,
} from '../../models/openapi/openapiTypes';

export const getValvesAsync = makeAsyncAction('GET_VALVES')<
  string,
  { customerId: string; valves: WsStreamingValveUpdatePayloadType[] },
  Error
>();

export const getThresholdAsync = makeAsyncAction('GET_THRESHOLDS')<
  string,
  ApiPressureThresholdView[],
  Error
>();

export const pairContainerToValveAsync = makeAsyncAction(
  'PAIR_CONTAINER_TO_VALVE'
)<
  {
    barcode: string;
    serialNumber: string;
    pairingType: ApiContainerValvePairingType;
  },
  { pairing: ApiContainerValvePairing; serialNumber: string },
  { error: Error; serialNumber: string }
>();

export const unpairContainerFromValveAsync = makeAsyncAction(
  'UNPAIR_CONTAINER_FROM_VALVE'
)<
  {
    serialNumber: string;
  },
  { serialNumber: string },
  { error: Error; serialNumber: string }
>();

export const updateValveNameAsync = makeAsyncAction('UPDATE_VALVE_NAME')<
  {
    serialNumber: string;
    newDeviceName: string | null;
    oldDeviceName: string | undefined;
  },
  {},
  { error: Error; oldDeviceName: string | undefined; serialNumber: string }
>();

export const createThresholdAsync = makeAsyncAction('CREATE_THRESHOLD')<
  {
    threshold: ApiPressureThresholdView;
    customerId: string;
  },
  {},
  Error
>();

export const updateThresholdAsync = makeAsyncAction('UPDATE_THRESHOLD')<
  {
    threshold: ApiPressureThresholdView;
    customerId: string;
  },
  {},
  Error
>();

export const deleteThresholdAsync = makeAsyncAction('DELETE_THRESHOLD')<
  {
    serialNumber: string;
    thresholdId: string;
    customerId: string;
  },
  {},
  Error
>();

export const getValveHistoryAsync = makeAsyncAction('GET_VALVE_HISTORY')<
  {
    serialNumber: string;
    deviceId: string;
    bucket: ValveHistoryBucket;
  },
  {
    items: ApiValveHistoryItem[];
    bucket: ValveHistoryBucket;
    serialNumber: string;
    deviceId: string;
  },
  {
    bucket: ValveHistoryBucket;
    serialNumber: string;
    deviceId: string;
    error: Error;
  }
>();

export const getValveHistoryExportAsync = makeAsyncAction(
  'GET_VALVE_HISTORY_EXPORT'
)<
  {
    serialNumber: string;
    deviceId: string;
    bucket: ValveHistoryBucket;
  },
  {
    data: string;
    bucket: ValveHistoryBucket;
    serialNumber: string;
    deviceId: string;
  },
  {
    bucket: ValveHistoryBucket;
    serialNumber: string;
    deviceId: string;
    error: Error;
  }
>();

export const getCustomerAssignmentForValveAsync = makeAsyncAction(
  'GET_ASSIGNMENT_FOR_VALVE'
)<
  {
    serialNumber: string;
  },
  {
    customerId: string;
    serialNumber: string;
    deviceId: string;
  },
  {
    serialNumber: string;
    error: Error;
  }
>();

export const changeValveGroupAssignmentAsync = makeAsyncAction(
  'CHANGE_VALVE_GROUP_ASSIGNMENT'
)<
  {
    deviceId: string;
    newGroupId: string | undefined;
    oldGroupId: string | undefined;
  },
  { deviceId: string },
  {
    deviceId: string;
    newGroupId?: string | undefined;
    oldGroupId?: string | undefined;
    error: Error;
  }
>();
