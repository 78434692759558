import styles from './ShopWizard.module.scss';
import { TextButton } from '../../../stories/TextButton';
import { Info } from '../../../stories/icons';
import ShopItemSelect from './ShopItemSelect';
import { Devider } from '../../../stories/Devider';
import { Button } from '../../../stories/Button';
import ShopConfiguratorDeviceInfo from './ShopConfiguratorDeviceInfo';
import React, { useState } from 'react';
import {
  ApiGetShopAddressesResponse,
  ApiGetShopItemsResponse,
} from '../../../models/openapi/openapiTypes';
import * as shopSelectors from '../selectors';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import * as shopActions from '../actions';
import { v4 as uuidv4 } from 'uuid';
import { CartItem } from '../models/Cart';

interface ComponentProps {
  shopItemEntries: ApiGetShopItemsResponse;
  addressData: ApiGetShopAddressesResponse;
}

const mapStateToProps = (state: RootState) => ({
  cart: shopSelectors.currentCart(state.shop),
});

const dispatchProps = {
  changeItemQuantity: shopActions.currentCartChangeItemQuantity,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopConfiguratorStepMeter: React.FC<Props> = ({
  shopItemEntries,
  cart,
  addressData,
  changeItemQuantity,
}) => {
  const [infoVisible, setInfoVisible] = useState<boolean>(false);
  const [nextItemId, setNextItemId] = useState<string>(uuidv4);
  const [nextCreationTs, setNextCreationTs] = useState<number>(Date.now());

  const availableMeters = shopItemEntries.filter(it => it.category === 'meter');

  const selectedMeters: CartItem[] = cart.items
    .filter(item => item.quantity > 0)
    .filter(
      item =>
        shopItemEntries.find(it => it.itemId === item.itemId)?.category ===
        'meter'
    );

  const onAddNew = () => {
    setNextItemId(uuidv4());
    setNextCreationTs(Date.now());

    const nextMeter = addressData.shippingAddresses
      .map(address => address.addressId)
      .map(addressId => availableMeters.map(meter => [meter.itemId, addressId]))
      .flat()
      .filter(
        ([itemId, addressId]) =>
          !selectedMeters.some(
            item =>
              item.itemId === itemId && item.shippingAddressId === addressId
          )
      )[0];

    changeItemQuantity({
      id: nextItemId,
      addedTs: nextCreationTs,
      itemId: nextMeter[0],
      quantity: 1,
      shippingAddressId: nextMeter[1],
    });
  };

  const sortedSelectedMeters = [...selectedMeters].sort(
    (a, b) => a.addedTs - b.addedTs
  );
  return (
    <>
      <div className={styles['gaas-shopPage--content--selection--info']}>
        <span>
          Bitte wählen Sie aus, ob das Gaas Meter für Sauerstoff geeignet sein
          muss und legen Sie fest, wie viele GaaS Meter Sie für welchen
          Gewindetyp benötigen.
        </span>
        <TextButton
          LeadingIcon={Info}
          onClick={() => setInfoVisible(!infoVisible)}
          inline
        />
      </div>
      {infoVisible && <ShopConfiguratorDeviceInfo />}

      {sortedSelectedMeters.map((item, index) => (
        <div key={`${item.id}wrapper`}>
          <ShopItemSelect
            item={item}
            price={
              availableMeters.find(meter => meter.itemId === item.itemId)!.price
            }
            name="meter"
            addresses={addressData}
            options={availableMeters.map(option => ({
              itemLabel: option.name,
              itemId: option.itemId,
            }))}
            disabledOptions={selectedMeters.map(item => ({
              shippingAddressId: item.shippingAddressId,
              itemId: item.itemId,
            }))}
            onItemQuantityAndAddressChanged={changeItemQuantity}
            key={`${item.itemId}${index}`}
          />
          <Devider noMargin />
        </div>
      ))}
      <div>
        <Button
          label={
            selectedMeters.length === 0 ? 'Hinzufügen' : 'Weitere hinzufügen'
          }
          variant="outlined"
          disabled={
            selectedMeters.length ===
            availableMeters.length * addressData.shippingAddresses.length
          }
          onClick={onAddNew}
        />
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  dispatchProps
)(ShopConfiguratorStepMeter);
