import { Observable } from 'rxjs';
import { apiDelete, apiGet, apiPost } from './api';
import { ApiPressureThresholdView } from '../models/openapi/openapiTypes';

export function getThresholdsForCustomer(
  customerId: string
): Observable<ApiPressureThresholdView[]> {
  return apiGet<ApiPressureThresholdView[]>(
    'GaasBackend',
    `/threshold/by-customer/${customerId}/list`,
    {}
  );
}

export function createThreshold(
  threshold: ApiPressureThresholdView
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/threshold/create`, threshold);
}

export function updateThreshold(
  threshold: ApiPressureThresholdView
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/threshold/update`, threshold);
}

export function deleteThreshold(
  thresholdId: string,
  serialNumber: string
): Observable<{}> {
  return apiDelete<{}>(
    'GaasBackend',
    `/threshold/by-id/${thresholdId}/for-device/${serialNumber}`,
    {}
  );
}
