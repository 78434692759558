import { createReducer } from 'typesafe-actions';
import {
  AsyncResource,
  asyncResourceFailure,
  asyncResourceRequest,
  asyncResourceSuccess,
} from '../../store/async-resource';
import { selectedCustomerChanged, signOutAsync } from '../common/actions';
import {
  changeValveGroupAssignmentAsync,
  createThresholdAsync,
  deleteThresholdAsync,
  getCustomerAssignmentForValveAsync,
  getThresholdAsync,
  getValveHistoryAsync,
  getValveHistoryExportAsync,
  getValvesAsync,
  pairContainerToValveAsync,
  unpairContainerFromValveAsync,
  updateThresholdAsync,
  updateValveNameAsync,
} from './actions';
import {
  ApiValveHistoryItem,
  ApiValveState,
  ApiContainerValvePairingType,
  ApiPressureThresholdView,
} from '../../models/openapi/openapiTypes';

interface State {
  valves?: AsyncResource<ApiValveState[]>;
  valveHistory?: {
    [k: string]: { [b: number]: AsyncResource<ApiValveHistoryItem[]> };
  };
  valveHistoryExport?: {
    [k: string]: { [b: number]: AsyncResource<string> };
  };
  thresholds?: AsyncResource<ApiPressureThresholdView[]>;
  containerPairingInProgress: { [deviceIdentifier: string]: boolean };
  containerUnpairingInProgress: { [deviceIdentifier: string]: boolean };
  assignmentForValve?: AsyncResource<{ deviceId: string; customerId: string }>;
}

const initialState = {
  containerPairingInProgress: {},
  containerUnpairingInProgress: {},
} as State;

export const digidrumiReducer = createReducer(initialState)
  .handleAction(selectedCustomerChanged, state => ({
    ...state,
    valves: undefined,
    thresholds: undefined,
  }))
  .handleAction(getValvesAsync.request, state => ({
    ...state,
    valves: asyncResourceRequest(),
  }))
  .handleAction(
    getValvesAsync.success,
    (state, { payload: { valves, customerId } }) => {
      const valveMap: Record<string, ApiValveState> = Object.fromEntries(
        (state.valves?.data ?? []).map(it => [it.deviceIdentifier, it])
      );
      valves.forEach(valve => {
        // skip the update if the valve does not belong to a customer.
        // this may happen if a websocket message was delayed in transport
        if (valve.customerId !== customerId) {
          return;
        }
        const existingValve = valveMap[valve.deviceIdentifier];
        const serialNumber = valve.serialNumber ?? existingValve?.serialNumber;
        const deviceName = valve.deviceName ?? existingValve?.deviceName;
        const groupId = valve.groupId ?? existingValve?.groupId;

        const newValve: ApiValveState = {
          ...existingValve,
          ...valve,
          deviceName,
          serialNumber,
          groupId,
          containerPairing: valve.containerPairing
            ? {
                ...valve.containerPairing,
                paringType: valve.containerPairing
                  .paringType as ApiContainerValvePairingType,
              }
            : undefined,
        };
        valveMap[valve.deviceIdentifier] = newValve;
      });

      return {
        ...state,
        valves: asyncResourceSuccess(Object.values(valveMap)),
      };
    }
  )
  .handleAction(getValvesAsync.failure, (state, { payload: error }) => ({
    ...state,
    valves: asyncResourceFailure(error),
  }))
  .handleAction(
    unpairContainerFromValveAsync.request,
    (state, { payload: { serialNumber } }) => ({
      ...state,
      containerUnpairingInProgress: {
        ...state.containerUnpairingInProgress,
        [serialNumber]: true,
      },
    })
  )
  .handleAction(
    unpairContainerFromValveAsync.success,
    (state, { payload: { serialNumber } }) => {
      const device = state.valves?.data?.find(
        it => it.serialNumber === serialNumber
      );
      if (device != null) {
        device.containerPairing = undefined;
      }

      return {
        ...state,
        containerUnpairingInProgress: {
          ...state.containerUnpairingInProgress,
          [serialNumber]: false,
        },
      };
    }
  )
  .handleAction(
    unpairContainerFromValveAsync.failure,
    (state, { payload: { serialNumber } }) => ({
      ...state,
      containerUnpairingInProgress: {
        ...state.containerUnpairingInProgress,
        [serialNumber]: false,
      },
    })
  )
  .handleAction(
    pairContainerToValveAsync.request,
    (state, { payload: { serialNumber } }) => ({
      ...state,
      containerPairingInProgress: {
        ...state.containerPairingInProgress,
        [serialNumber]: true,
      },
    })
  )
  .handleAction(
    pairContainerToValveAsync.success,
    (state, { payload: { pairing, serialNumber } }) => {
      const device = state.valves?.data?.find(
        it => it.serialNumber === serialNumber
      );
      if (device != null) {
        device.containerPairing = pairing;
      }
      return {
        ...state,
        containerPairingInProgress: {
          ...state.containerPairingInProgress,
          [serialNumber]: false,
        },
      };
    }
  )
  .handleAction(
    pairContainerToValveAsync.failure,
    (state, { payload: { serialNumber } }) => ({
      ...state,
      containerPairingInProgress: {
        ...state.containerPairingInProgress,
        [serialNumber]: true,
      },
    })
  )
  .handleAction(getThresholdAsync.request, state => ({
    ...state,
    thresholds: asyncResourceRequest(),
  }))
  .handleAction(
    getThresholdAsync.success,
    (state, { payload: thresholds }) => ({
      ...state,
      thresholds: asyncResourceSuccess(thresholds),
    })
  )
  .handleAction(getThresholdAsync.failure, (state, { payload: error }) => ({
    ...state,
    thresholds: asyncResourceFailure(error),
  }))

  .handleAction(
    getValveHistoryAsync.request,
    (state, { payload: { bucket, deviceId } }) => ({
      ...state,
      valveHistory: {
        ...(state.valveHistory ?? {}),
        [deviceId]: {
          ...(state.valveHistory?.[deviceId] ?? {}),
          [bucket]: asyncResourceRequest(),
        },
      },
    })
  )
  .handleAction(
    getValveHistoryAsync.success,
    (state, { payload: { items, bucket, deviceId } }) => ({
      ...state,
      valveHistory: {
        ...(state.valveHistory ?? {}),
        [deviceId]: {
          ...(state.valveHistory?.[deviceId] ?? {}),
          [bucket]: asyncResourceSuccess(items),
        },
      },
    })
  )
  .handleAction(
    getValveHistoryAsync.failure,
    (state, { payload: { error, deviceId, bucket } }) => ({
      ...state,
      valveHistory: {
        ...(state.valveHistory ?? {}),
        [deviceId]: {
          ...(state.valveHistory?.[deviceId] ?? {}),
          [bucket]: asyncResourceFailure(error),
        },
      },
    })
  )

  .handleAction(
    getValveHistoryExportAsync.request,
    (state, { payload: { bucket, deviceId } }) => ({
      ...state,
      valveHistoryExport: {
        ...(state.valveHistoryExport ?? {}),
        [deviceId]: {
          ...(state.valveHistoryExport?.[deviceId] ?? {}),
          [bucket]: asyncResourceRequest(),
        },
      },
    })
  )
  .handleAction(
    getValveHistoryExportAsync.success,
    (state, { payload: { data, bucket, deviceId } }) => ({
      ...state,
      valveHistoryExport: {
        ...(state.valveHistoryExport ?? {}),
        [deviceId]: {
          ...(state.valveHistoryExport?.[deviceId] ?? {}),
          [bucket]: asyncResourceSuccess(data),
        },
      },
    })
  )
  .handleAction(
    getValveHistoryExportAsync.failure,
    (state, { payload: { error, deviceId, bucket } }) => ({
      ...state,
      valveHistoryExport: {
        ...(state.valveHistoryExport ?? {}),
        [deviceId]: {
          ...(state.valveHistoryExport?.[deviceId] ?? {}),
          [bucket]: asyncResourceFailure(error),
        },
      },
    })
  )
  .handleAction(
    createThresholdAsync.request,
    (state, { payload: { threshold } }) => {
      const newState = { ...state };
      const prevThresholds = newState.thresholds?.data ?? [];
      prevThresholds.push(threshold);
      newState.thresholds = asyncResourceSuccess(prevThresholds);
      return newState;
    }
  )
  .handleAction(
    updateThresholdAsync.request,
    (state, { payload: { threshold } }) => {
      const newState = { ...state };
      const newThresholds = (newState.thresholds?.data ?? []).filter(
        t => t.id !== threshold.id
      );
      newThresholds.push(threshold);
      newState.thresholds = asyncResourceSuccess(newThresholds);
      return newState;
    }
  )
  .handleAction(
    deleteThresholdAsync.request,
    (state, { payload: { thresholdId } }) => {
      const newState = { ...state };
      const newThresholds = (newState.thresholds?.data ?? []).filter(
        threshold => threshold.id !== thresholdId
      );
      newState.thresholds = asyncResourceSuccess(newThresholds);
      return newState;
    }
  )
  .handleAction(getCustomerAssignmentForValveAsync.request, state => ({
    ...state,
    assignmentForValve: asyncResourceRequest(),
  }))
  .handleAction(
    getCustomerAssignmentForValveAsync.success,
    (state, { payload }) => ({
      ...state,
      assignmentForValve: asyncResourceSuccess(payload),
    })
  )
  .handleAction(
    getCustomerAssignmentForValveAsync.failure,
    (state, { payload: { error } }) => ({
      ...state,
      assignmentForValve: asyncResourceFailure(error),
    })
  )

  .handleAction(updateValveNameAsync.request, (state, { payload }) => ({
    ...state,
    valves: asyncResourceSuccess(
      (state.valves?.data ?? []).map(valve =>
        valve.serialNumber === payload.serialNumber
          ? { ...valve, deviceName: payload.newDeviceName ?? undefined }
          : valve
      )
    ),
  }))
  .handleAction(
    updateValveNameAsync.failure,
    (state, { payload: { serialNumber, oldDeviceName } }) => ({
      ...state,
      valves: asyncResourceSuccess(
        (state.valves?.data ?? []).map(valve =>
          valve.serialNumber === serialNumber
            ? { ...valve, deviceName: oldDeviceName }
            : valve
        )
      ),
    })
  )
  .handleAction(
    changeValveGroupAssignmentAsync.request,
    (state, { payload }) => ({
      ...state,
      valves: asyncResourceSuccess(
        (state.valves?.data ?? []).map(valve =>
          valve.deviceIdentifier === payload.deviceId
            ? { ...valve, groupId: payload.newGroupId }
            : valve
        )
      ),
    })
  )
  .handleAction(
    changeValveGroupAssignmentAsync.failure,
    (state, { payload }) => ({
      ...state,
      valves: asyncResourceSuccess(
        (state.valves?.data ?? []).map(valve =>
          valve.deviceIdentifier === payload.deviceId
            ? { ...valve, groupId: payload.oldGroupId }
            : valve
        )
      ),
    })
  )

  .handleAction(signOutAsync.success, () => ({
    ...initialState,
  }));

export default digidrumiReducer;
export type DigidrumiState = ReturnType<typeof digidrumiReducer>;
