import React from 'react';
import styles from './ShippingAddress.module.scss';
import { Package } from './icons';
import { Skeleton } from '@mui/material';

interface Props {
  name?: string;
  street?: string;
  zipCode?: string;
  city?: string;
  noDecoration?: boolean;
  icon?: JSX.Element;
}

export const ShippingAddress: React.FC<Props> = ({
  name,
  street,
  zipCode,
  city,
  noDecoration,
  icon,
}) => {
  let Content = () => {
    if (!name || !street || !zipCode || !city) {
      return (
        <div className={styles['gaas-shippingAddress--address']}>
          <Skeleton height={22.4} width={160} />
          <Skeleton height={22.4} width={140} />
          <Skeleton height={22.4} width={100} />
        </div>
      );
    } else
      return (
        <div className={styles['gaas-shippingAddress--address']}>
          <span>{name}</span>
          <span>{street}</span>
          <span>
            {zipCode} {city}
          </span>
        </div>
      );
  };

  if (noDecoration) {
    return <Content />;
  }

  let addressIcon = icon ? icon : <Package />;
  return (
    <div className={styles['gaas-shippingAddress']}>
      <div className={styles['gaas-shippingAddress--icon']}>{addressIcon}</div>
      <Content />
    </div>
  );
};
