/**
 * This functions converts a price value, received from the backend, to a
 * string that fulfills the aesthetic requirements of the shop as set
 * by Patrick Lappe.
 *
 * Prices are calculated by the backend are generated using BigNumber.js and converted to
 * a string before they are send. The value is rounded to two decimal places
 * and always uses the english notation with a dot as decimal separator.
 *
 * The following rules how prices should be displayed were set by Patrick Lappe
 * on 2024-11-21:
 * - if the price ends on .00, the .00 should be removed
 * - if the price ends on .XY, the . should be replaced by the applicable
 *   decimal separator (, in Germany)
 * @param price
 */
export function bigNumberToShopPrice(price: string): string {
  // remove .00 if it is at the end of the string
  const priceWithoutDecimal = price.replace(/\.00$/g, '');
  if (priceWithoutDecimal !== price) {
    return priceWithoutDecimal;
  }
  // replace .XY with ,XY, but only if the decimal separator is
  // as the position that we expect it to be
  if (price.charAt(price.length - 3) === '.') {
    return `${price.slice(0, price.length - 3)},${price.slice(
      price.length - 2
    )}`;
  }
  // if none of the rules above apply,
  // it is very likely that we have encountered an unexpected price format,
  // and it is safest to return the price as is
  return price;
}
