import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './Select.module.scss';
import classNames from 'classnames';
import { LabelAndText } from './LabelAndText';
import { Check, ChevronDownSmall, ChevronUpSmall, Info } from './icons';
import { TextButton } from './TextButton';
import { InfoBox } from './InfoBox';
import useWindowSize from '../hooks/useWindowSize';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';
import { PlansChip } from './PlansChip';
import { PlansModal } from './PlansModal';

interface Option {
  label: string;
  value: any;
  additionalContent?: string | ReactNode;
  disabled?: boolean;
}

interface Props {
  label: string;
  id: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  size?: 's' | 'm' | 'l';
  unit?: string;
  info?: string;
  undefinedOptionValue?: string;
  permission?: PermissionQueryResponse;
}

export const Select: React.FC<Props> = ({
  label,
  id,
  options,
  value,
  onChange,
  disabled,
  required,
  error,
  errorMessage,
  size = 'm',
  unit,
  info,
  undefinedOptionValue,
  permission,
}) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [dropdownOverflows, setDropdownOverflow] = useState(false);
  const [plansModalOpen, setPlansModalOpen] = useState(false);

  const inputRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  let { height } = useWindowSize();

  useEffect(() => {
    if (dropdownRef.current) {
      if (options.length <= 5) return;

      if (optionsVisible) {
        //el postiion from window top - generic padding
        let drowpdownHeight =
          height - dropdownRef.current.getBoundingClientRect().top - 24;

        if (dropdownRef.current.clientHeight > drowpdownHeight) {
          setDropdownOverflow(true);
          dropdownRef.current.style.height = drowpdownHeight + 'px';
        }
      }
    }
  }, [height, optionsVisible, options.length]);

  const onOutsideClick = useCallback(
    (e: PointerEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        setOptionsVisible(false);
      }
    },
    [setOptionsVisible]
  );

  useEffect(() => {
    window.addEventListener('pointerdown', e => onOutsideClick(e));

    return () =>
      window.removeEventListener('pointerdown', e => onOutsideClick(e));
  }, [onOutsideClick]);

  let displayValue;
  if (undefinedOptionValue !== undefined && undefinedOptionValue === value) {
    displayValue = '';
  } else {
    displayValue = options.find(option => value === option.value)?.label;
  }

  const missingPermissions =
    permission !== undefined && permission?.[0] !== 'GRANTED';
  const disabledThroughPermissions = missingPermissions || (disabled ?? false);
  return (
    <div
      className={classNames(
        styles['gaas-select'],
        size && styles[`gaas-select--${size}`],
        {
          [styles['gaas-select--disabled']]: disabledThroughPermissions,
          [styles['gaas-select--error']]: error,
        }
      )}
    >
      <div className={styles['gaas-select--label']}>
        <LabelAndText label={label} labelFor={id} />
        {info && (
          <TextButton
            LeadingIcon={Info}
            onClick={() => setInfoVisible(!infoVisible)}
            inline
          />
        )}
      </div>
      <div className={styles['gaas-select--input']} ref={inputRef}>
        <input
          id={id}
          placeholder="Auswählen"
          disabled={disabledThroughPermissions}
          required={required}
          value={displayValue}
          onClick={() => setOptionsVisible(!optionsVisible)}
          readOnly
        />
        {optionsVisible && (
          <div
            className={classNames(styles['gaas-select--input--options'], {
              [styles['gaas-select--input--options--withScroll']]:
                dropdownOverflows,
            })}
            ref={dropdownRef}
          >
            {options.map(option => (
              <div
                className={classNames(
                  styles['gaas-select--input--options--option'],
                  {
                    [styles['gaas-select--input--options--option--selected']]:
                      option.value === value,
                    [styles['gaas-select--input--options--option--disabled']]:
                      option.disabled,
                    [styles[
                      'gaas-select--input--options--option--withContent'
                    ]]: option.additionalContent,
                  }
                )}
                onClick={() => {
                  if (option.disabled) return;

                  onChange(option.value);
                  setOptionsVisible(false);
                }}
                key={option.value}
                aria-disabled={option.disabled}
              >
                <span
                  className={
                    styles['gaas-select--input--options--option--title']
                  }
                >
                  {option.label}
                </span>
                {option.value === value && (
                  <Check
                    className={
                      styles['gaas-select--input--options--option--icon']
                    }
                  />
                )}
                {option.additionalContent &&
                typeof option.additionalContent === 'string' ? (
                  <span
                    className={
                      styles['gaas-select--input--options--option--content']
                    }
                  >
                    {option.additionalContent}
                  </span>
                ) : (
                  option.additionalContent
                )}
              </div>
            ))}
          </div>
        )}
        {infoVisible && (
          <InfoBox>
            <span>{info}</span>
          </InfoBox>
        )}
        {!optionsVisible ? (
          <ChevronDownSmall className={styles['gaas-select--input--icon']} />
        ) : (
          <ChevronUpSmall className={styles['gaas-select--input--icon']} />
        )}
        {unit && (
          <span className={styles['gaas-select--input--unit']}>{unit}</span>
        )}
        {error && (
          <p className={styles['gaas-select--input--hint']}>{errorMessage}</p>
        )}
        {missingPermissions && permission !== undefined && (
          <>
            <PlansChip
              permission={permission}
              onClick={() => setPlansModalOpen(true)}
              top={'50%'}
              right={'50%'}
            />
            <PlansModal
              open={plansModalOpen}
              onClose={() => setPlansModalOpen(false)}
              permission={permission}
            />
          </>
        )}
      </div>
    </div>
  );
};
