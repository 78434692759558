import ShopCartProvider from './ShopCartProvider';
import ShopNewSubscriptionWizard from './ShopNewSubscriptionWizard';
import React from 'react';
import { useShopCustomerNumber } from '../util/ShopCustomerNumber';
import styles from '../screens/ShopOrderConfirmationPage.module.scss';
import { GaasSubscription } from '../../../models/openapi/openapiTypes';

const ShopSubscriptionCreateOrUpdateGuard: React.FC = () => {
  const { existingSubscription } = useShopCustomerNumber();

  if (!existingSubscription || existingSubscription === GaasSubscription.Free) {
    return (
      <ShopCartProvider>
        <ShopNewSubscriptionWizard />
      </ShopCartProvider>
    );
  }

  return (
    <div className={styles['gaas-shopConfirm--background']}>
      <div className={styles['gaas-shopConfirm']}>
        <h1>Dieses Kundenkonto nutzt bereits GaaS</h1>
        <p>
          Das von Ihnen gewählte Kundenkonto besitzt bereits ein
          GaaS-Abbonement.
        </p>
        <p>
          Zur Zeit können Sie Ihr bestehendens GaaS-Abbonement noch nicht
          ändern. Sollten Sie Änderungen an Ihrem Abbonement vornehmen wollen,
          wenden Sie sich bitte an{' '}
          <a href="mailto:support@mail.gaas.westfalen.com">
            support@mail.gaas.westfalen.com
          </a>
          . In Zukunft wird es möglich sein, Ihr bestehendes Abbonement an
          dieser Stelle zu verwalten.
        </p>
      </div>
    </div>
  );
};

export default ShopSubscriptionCreateOrUpdateGuard;
