import classNames from 'classnames';
import moment from 'moment';
import React, { AriaRole, useMemo, useState } from 'react';
import {
  PinColors,
  pinColorToColorValue,
} from '../features/common/models/pin-colors';
import { ContainerStateView } from '../features/inventory/models/inventory';
import { InventoryThresholdPassedEventView } from '../features/inventory/models/inventory-threshold-passed-event-view';
import { InventoryThresholdView } from '../features/inventory/models/inventory-threshold-view';
import useWindowSize from '../hooks/useWindowSize';
import { Bottles } from './Bottles';
import { Button } from './Button';
import { Card } from './Card';
import { Devider } from './Devider';
import styles from './MaterialCard.module.scss';
import { MaterialList, MaterialListItem } from './MaterialList';
import { MaterialTimelineItem } from './MaterialTimelineItem';
import { MaterialWarning } from './MaterialWarning';
import { PinModal, PinModalThresholdData } from './PinModal';
import { TextButton } from './TextButton';
import {
  Chart,
  Edit,
  List,
  NewLabel,
  Notifications,
  ShoppingCart,
} from './icons';
import { Loading } from './Loading';
import { Tooltip } from './Tooltip';
import BottleList from './BottleList';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  focused: boolean;
  customerId: string;
  containerType: string;
  materialDescription: string;
  materialId: string;
  materialShopLink: string;
  bottles: {
    full: number;
    open: number;
    empty: number;
    ordered: number;
  };
  containers: ContainerStateView[];
  thresholds: InventoryThresholdView[];
  thresholdsLoading: boolean;
  thresholdsPassed: {
    threshold: InventoryThresholdView;
    alert: InventoryThresholdPassedEventView;
  }[];
  thresholdPassedEventsLoading: boolean;
  addPin: (threshold: PinModalThresholdData) => void;
  editPin: (
    threshold: InventoryThresholdView,
    updatedThreshold: PinModalThresholdData
  ) => void;
  deletePin: (threshold: InventoryThresholdView) => void;
  role?: AriaRole;
  permissionReadStatistics: PermissionQueryResponse;
  permissionWriteAlarm: PermissionQueryResponse;
}

export const MaterialCard: React.FC<Props> = ({
  focused,
  customerId,
  containerType,
  materialDescription,
  materialId,
  materialShopLink,
  bottles,
  containers,
  thresholds,
  thresholdsLoading,
  thresholdsPassed,
  thresholdPassedEventsLoading,
  addPin,
  editPin,
  deletePin,
  role,
  permissionReadStatistics,
  permissionWriteAlarm,
}) => {
  const [activeTab, setActiveTab] = useState<
    'bottles' | 'statistics' | 'pins' | undefined
  >();
  const [addPinModalOpen, setAddPinModalOpen] = useState(false);
  const [editPinModalOpen, setEditPinModalOpen] = useState(false);
  const [currentPin, setCurrentPin] = useState<InventoryThresholdView | null>(
    null
  );
  const windowWidth = useWindowSize().width;

  const [writeAlarm] = permissionWriteAlarm;

  useMemo(() => {
    if (currentPin) {
      setEditPinModalOpen(true);
    }
  }, [currentPin]);

  const ExpandedContent = () => {
    if (activeTab === 'bottles') {
      return <BottleList customerId={customerId} containers={containers} />;
    } else if (activeTab === 'statistics') {
      return <div>Statistik befindet sich im Aufbau.</div>;
    } else if (activeTab === 'pins') {
      const grid = writeAlarm ? '1fr 1fr 24px' : '1fr 1fr';

      if (thresholdsLoading || thresholdPassedEventsLoading) {
        return (
          <div
            className={styles['gaas-material-card--expandedContent--loading']}
          >
            <Loading />
          </div>
        );
      }

      return (
        <>
          {thresholdsPassed.length > 0 && (
            <>
              <div>
                <h4 style={{ marginTop: 0, textAlign: 'center' }}>
                  Alarm unterschritten
                </h4>
                {thresholdsPassed.map((pin, pinIndex, pinArray) => {
                  return (
                    <MaterialTimelineItem
                      pinName={pin.threshold.name}
                      date={moment(pin.alert.start).fromNow()}
                      color={pin.threshold.color}
                      connector={pinIndex !== pinArray.length - 1}
                      key={pin.threshold.id}
                    />
                  );
                })}
              </div>
              <Devider noMargin />
            </>
          )}
          {thresholds.length > 0 && (
            <MaterialList
              grid={grid}
              labels={['Name', 'Schwellwert']}
              items={thresholds.map(pin => (
                <MaterialListItem grid={grid} key={pin.id}>
                  <div className={styles['gaas-material-card--pin']}>
                    <div
                      className={classNames(
                        styles['gaas-material-card--pin--dot'],
                        styles[
                          `gaas-material-card--pin--dot--${pinColorToColorValue(
                            pin.color as PinColors
                          )}`
                        ]
                      )}
                    />
                    <div>{pin.name}</div>
                  </div>

                  <div className={styles['gaas-material-card--threshold']}>
                    {pin.minFullBottles === 1
                      ? pin.minFullBottles + ' Flasche'
                      : pin.minFullBottles + ' Flaschen'}
                  </div>

                  {writeAlarm && (
                    <TextButton
                      LeadingIcon={Edit}
                      onClick={() => {
                        setCurrentPin(pin);
                        setEditPinModalOpen(true);
                      }}
                    />
                  )}
                </MaterialListItem>
              ))}
            />
          )}

          <Button
            label="Alarm hinzufügen"
            LeadingIcon={NewLabel}
            fullWidth
            onClick={() => setAddPinModalOpen(true)}
            permission={permissionWriteAlarm}
          />
        </>
      );
    } else return null;
  };

  return (
    <>
      <Card
        focused={focused}
        id={containerType}
        maxWidth={450}
        minWidth={
          windowWidth >= 1024 && windowWidth <= 1149
            ? 450
            : windowWidth >= 1150 && windowWidth <= 1439
            ? 0
            : windowWidth >= 1440
            ? 450
            : 0
        }
        role={role}
      >
        <div className={styles['gaas-material-card']}>
          <div>
            <h3 className={styles['gaas-material-card--title']}>
              {containerType}
            </h3>
            <div className={styles['gaas-material-card--warnings']}>
              {thresholdsPassed.map(pin => (
                <MaterialWarning
                  color={pin.threshold.color as PinColors}
                  key={pin.threshold.id}
                />
              ))}
            </div>

            <span className={styles['gaas-material-card--description']}>
              {materialDescription} • {materialId}
            </span>
          </div>

          <Bottles
            full={bottles.full}
            open={bottles.open}
            empty={bottles.empty}
            ordered={bottles.ordered}
          />

          <div className={styles['gaas-material-card--actions']}>
            <div className={styles['gaas-material-card--actions--tabs']}>
              <div
                className={classNames(
                  styles['gaas-material-card--actions--tabs--tab'],
                  {
                    [styles['gaas-material-card--actions--tabs--tab--active']]:
                      activeTab === 'bottles',
                  }
                )}
              >
                <TextButton
                  LeadingIcon={List}
                  title={'bottles'}
                  onClick={() =>
                    activeTab !== 'bottles'
                      ? setActiveTab('bottles')
                      : setActiveTab(undefined)
                  }
                  active={activeTab === 'bottles'}
                  size="l"
                />
              </div>
              <div
                className={classNames(
                  styles['gaas-material-card--actions--tabs--tab'],
                  {
                    [styles['gaas-material-card--actions--tabs--tab--active']]:
                      activeTab === 'statistics',
                  }
                )}
              >
                <TextButton
                  title={'statistics'}
                  LeadingIcon={Chart}
                  onClick={() =>
                    activeTab !== 'statistics'
                      ? setActiveTab('statistics')
                      : setActiveTab(undefined)
                  }
                  active={activeTab === 'statistics'}
                  size="l"
                  permission={permissionReadStatistics}
                />
              </div>
              <div
                className={classNames(
                  styles['gaas-material-card--actions--tabs--tab'],
                  {
                    [styles['gaas-material-card--actions--tabs--tab--active']]:
                      activeTab === 'pins',
                  }
                )}
              >
                <TextButton
                  title={'alerts'}
                  LeadingIcon={Notifications}
                  onClick={() =>
                    activeTab !== 'pins'
                      ? setActiveTab('pins')
                      : setActiveTab(undefined)
                  }
                  active={activeTab === 'pins'}
                  badgeCount={thresholds.length}
                  size="l"
                />
              </div>
            </div>

            <Tooltip text="Zum Gase-Shop">
              <div className={styles['gaas-material-card--actions--tabs--tab']}>
                <a
                  className={styles['gaas-material-card--buttons--right']}
                  href={materialShopLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TextButton LeadingIcon={ShoppingCart} size="l" />
                </a>
              </div>
            </Tooltip>
          </div>

          {activeTab && (
            <>
              <Devider noMargin />
              {ExpandedContent()}
            </>
          )}
        </div>
      </Card>

      <PinModal
        action="add"
        open={addPinModalOpen}
        onClose={() => setAddPinModalOpen(false)}
        variant="inventory"
        onSave={newPin => addPin(newPin)}
      />

      {currentPin && (
        <PinModal
          action="edit"
          open={editPinModalOpen}
          onClose={() => setEditPinModalOpen(false)}
          variant="inventory"
          onSave={updatedPin => editPin(currentPin, updatedPin)}
          currentColor={currentPin.color}
          currentName={currentPin.name}
          currentValue={currentPin.minFullBottles}
          emailNotifications={currentPin.emailRecipients}
          portalNotification={currentPin.portalNotification}
          onDelete={() => {
            deletePin(currentPin);
            setCurrentPin(null);
          }}
          key={currentPin.id}
        />
      )}
    </>
  );
};
