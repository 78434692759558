import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { RootState } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import { ManometerCard } from '../../../stories/ManometerCard';
import { selectedCustomer } from '../../common/selectors';
import * as digidrumiActions from '../actions';
import DigiDrumiHistoryModal from './DigiDrumiHistoryModal';
import DigiDrumiPairing from './DigiDrumiPairing';
import * as commonSelectors from '../../common/selectors';
import {
  ApiGaasAction,
  ApiValveState,
  ApiPressureThresholdView,
} from '../../../models/openapi/openapiTypes';
import { CustomerManagedGroup } from '../../groups/models/customer-managed-group';

const mapStateToProps = (state: RootState) => ({
  currentCustomer: selectedCustomer(state.common),
  hasPermission: (action: ApiGaasAction) =>
    commonSelectors.hasPermission(action, state.common),
});

const dispatchProps = {
  createThreshold: digidrumiActions.createThresholdAsync.request,
  deleteThreshold: digidrumiActions.deleteThresholdAsync.request,
  updateThreshold: digidrumiActions.updateThresholdAsync.request,
  updateValveName: digidrumiActions.updateValveNameAsync.request,
  changeValveGroupAssignment:
    digidrumiActions.changeValveGroupAssignmentAsync.request,
};

type ComponentProps = {
  valve: ApiValveState;
  thresholds: ApiPressureThresholdView[];
  groups: CustomerManagedGroup[];
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const DigiDrumiCard: React.FC<Props> = ({
  valve,
  thresholds,
  deleteThreshold,
  createThreshold,
  updateThreshold,
  currentCustomer,
  updateValveName,
  changeValveGroupAssignment,
  hasPermission,
  groups,
}) => {
  //Glow on Route match
  interface RouteParams {
    serialNumber: string;
  }
  let { serialNumber: routeSerialNumber } = useParams<RouteParams>();
  // check if there is additional state
  // this timestamp allows us to trigger the fade effect again,
  // even if the route does not change
  const { state } = useLocation() as {
    state: { notificationAccessedAt: number };
  };
  const notificationAccessedAt = state?.notificationAccessedAt;
  const [focused, setFocused] = useState(false);
  const { restart: restartFocusTimer } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
    onExpire: () => {
      setFocused(false);
    },
  });

  const serialNumber = valve.serialNumber;
  useEffect(() => {
    if (serialNumber === routeSerialNumber) {
      setFocused(true);
      restartFocusTimer(new Date(Date.now() + 2000), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumber, routeSerialNumber, notificationAccessedAt]);

  const [showHistory, setShowHistory] = useState(false);

  return (
    <ManometerCard
      focused={focused}
      deviceName={valve.deviceIdentifier}
      batteryCharging={valve.isCharging}
      batteryFillLevel={
        // for the value range, see
        // https://bitbucket.org/odpat7/gaas-meter-nrf52/src/6b8f46dc71288204750fd95f58fd0e792a0cf6ca/config/app_config.h?at=release%2Fv2.0.1#lines-162
        valve.voltage != null
          ? Math.min(1, Math.max(0, (valve.voltage - 3.2) / (4 - 3.2))) * 100
          : valve.voltage
      }
      serialNumber={valve.serialNumber}
      customName={valve.deviceName ?? ''}
      onNameChange={newName =>
        updateValveName({
          serialNumber: valve.serialNumber,
          newDeviceName: newName,
          oldDeviceName:
            valve.deviceName === null ? undefined : valve.deviceName,
        })
      }
      pressureBar={valve.pressureBar}
      fillPressureBar={valve.containerPairing?.container?.fillPressure}
      temperatureCelsius={valve.temperatureManometerCelsius}
      updateTimestamp={valve.timestamp}
      thresholds={thresholds}
      groups={groups}
      groupId={valve.groupId}
      onGroupChange={(newGroupId: string | undefined) =>
        changeValveGroupAssignment({
          deviceId: valve.deviceIdentifier,
          oldGroupId: valve.groupId,
          newGroupId,
        })
      }
      addPin={threshold => {
        createThreshold({
          threshold: {
            ...threshold,
            deviceId: valve.deviceIdentifier,
            id: uuidv4(),
            pressureBar: threshold.thresholdValue,
          },
          customerId: currentCustomer!.customerId,
        });
      }}
      editPin={(threshold, updatedThreshold) =>
        updateThreshold({
          threshold: {
            ...updatedThreshold,
            id: threshold.id,
            deviceId: threshold.deviceId,
            pressureBar: updatedThreshold.thresholdValue,
          },
          customerId: currentCustomer!.customerId,
        })
      }
      deletePin={threshold =>
        deleteThreshold({
          thresholdId: threshold.id,
          serialNumber,
          customerId: currentCustomer!.customerId,
        })
      }
      ManometerPairingElement={
        <DigiDrumiPairing
          customerId={currentCustomer!.customerId}
          serialNumber={serialNumber}
          currentPairing={valve.containerPairing}
        />
      }
      setShowHistory={setShowHistory}
      HistoryModal={
        <DigiDrumiHistoryModal
          open={showHistory}
          onClose={() => setShowHistory(false)}
          deviceId={valve.deviceIdentifier}
          serialNumber={serialNumber}
        />
      }
      permissionReadHistory={hasPermission('meter.history.read')}
      permissionWriteThershold={hasPermission('threshold.pressure.write')}
      permissionChangeMeterName={hasPermission('meter.write')}
      permissionChangeMeterGroup={hasPermission('group.membership.write')}
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(DigiDrumiCard);
