import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AdminListValveEventsResponse,
  SuspiciousValueDiagnosticEvent,
  ThresholdPassedEventAdminView,
  ValveOfflineDiagnosticEvent,
} from '../features/administration/models/admin-list-valve-events-response';
import { DynamoDbKey } from '../features/administration/models/dynamo-db-key';
import {
  GatewayAssignment,
  GatewayWithCurrentAssignment,
} from '../features/administration/models/gateway-with-assignment';
import {
  ValveCustomerAssignment,
  ValveWithCurrentAssignment,
} from '../features/administration/models/valves-with-assignment';
import {
  ListingParameters,
  SortingParameters,
} from '../features/common/models/listing-parameters';
import { PaginatedEntityList } from '../features/common/models/paginated-entity-list';
import {
  ApiGetValveAssignmentResponse,
  ApiValveState,
  ApiValveHistoryItem,
  ApiContainerValvePairing,
  ApiContainerValvePairingType,
  ApiValveGroupAssignment,
} from '../models/openapi/openapiTypes';
import { apiDelete, apiGet, apiPost, apiPut } from './api';

export function getValvesForCustomer(
  customerId: string
): Observable<ApiValveState[]> {
  return apiGet<ApiValveState[]>(
    'GaasBackend',
    `/customers/${customerId}/valves`
  );
}

function hydrateGatewayAssignment(it: GatewayAssignment): GatewayAssignment {
  return {
    ...it,
    startTs:
      it.startTs != null
        ? // @ts-ignore
          new Date(Date.parse(it.startTs))
        : it.startTs,
    endTs:
      it.endTs != null
        ? // @ts-ignore
          new Date(Date.parse(it.endTs))
        : it.endTs,
  };
}

function hydrateGatewayWithAssignment(
  it: GatewayWithCurrentAssignment
): GatewayWithCurrentAssignment {
  return {
    ...it,
    assignment:
      it?.assignment != null ? hydrateGatewayAssignment(it.assignment) : null,
  };
}

export function listGatewaysForAdmins(
  params: SortingParameters
): Observable<PaginatedEntityList<GatewayWithCurrentAssignment>> {
  const search =
    Object.entries(params.search ?? {}).length > 0
      ? `&search=${Object.entries(params.search!)[0][1]}`
      : '';

  return apiGet<PaginatedEntityList<GatewayWithCurrentAssignment>>(
    'GaasBackend',
    `/admin/gateways/list?limit=${params.size}&offset=${params.start}${search}`
  ).pipe(
    map(vals => {
      return {
        numTotal: vals.numTotal,
        entities: vals.entities.map(hydrateGatewayWithAssignment),
      };
    })
  );
}

export function getGatewayForAdmins(
  id: string
): Observable<GatewayWithCurrentAssignment> {
  return apiGet<GatewayWithCurrentAssignment>(
    'GaasBackend',
    `/admin/gateway/get/${id}`
  ).pipe(map(entity => hydrateGatewayWithAssignment(entity)));
}

function hydrateValveAssignment(
  it: ValveCustomerAssignment
): ValveCustomerAssignment {
  return {
    ...it,
    startTs:
      it.startTs != null
        ? // @ts-ignore
          new Date(Date.parse(it.startTs))
        : it.startTs,
    endTs:
      it.endTs != null
        ? // @ts-ignore
          new Date(Date.parse(it.endTs))
        : it.endTs,
  };
}

function hydrateValveWithAssignment(
  it: ValveWithCurrentAssignment
): ValveWithCurrentAssignment {
  return {
    ...it,
    assignment:
      it?.assignment != null ? hydrateValveAssignment(it.assignment) : null,
  };
}
export function listValvesForAdmins(
  params: SortingParameters
): Observable<PaginatedEntityList<ValveWithCurrentAssignment>> {
  const search =
    Object.entries(params.search ?? {}).length > 0
      ? `&search=${Object.entries(params.search!)[0][1]}`
      : '';

  return apiGet<PaginatedEntityList<ValveWithCurrentAssignment>>(
    'GaasBackend',
    `/admin/valves/list?limit=${params.size}&offset=${params.start}${search}`
  ).pipe(
    map(vals => {
      return {
        numTotal: vals.numTotal,
        entities: vals.entities.map(hydrateValveWithAssignment),
      };
    })
  );
}

export function getValveForAdmins(
  id: string
): Observable<ValveWithCurrentAssignment> {
  return apiGet<ValveWithCurrentAssignment>(
    'GaasBackend',
    `/admin/valves/get/${id}`
  ).pipe(map(entity => hydrateValveWithAssignment(entity)));
}

export function getValveAssignments(
  valveId: string,
  params: ListingParameters
): Observable<PaginatedEntityList<ValveCustomerAssignment>> {
  return apiGet<PaginatedEntityList<ValveCustomerAssignment>>(
    'GaasBackend',
    `/admin/valves-assignments/${valveId}/list?limit=${params.size}&offset=${params.start}`
  ).pipe(
    map(response => ({
      numTotal: response.numTotal,
      entities: response.entities.map(it => hydrateValveAssignment(it)),
    }))
  );
}

export function getGatewayAssignments(
  gatewayId: string,
  params: ListingParameters
): Observable<PaginatedEntityList<GatewayAssignment>> {
  return apiGet<PaginatedEntityList<GatewayAssignment>>(
    'GaasBackend',
    `/admin/gateway-assignments/${gatewayId}/list?limit=${params.size}&offset=${params.start}`
  ).pipe(
    map(response => ({
      numTotal: response.numTotal,
      entities: response.entities.map(it => hydrateGatewayAssignment(it)),
    }))
  );
}

export function getValveOfflineEvents(
  valveId: string,
  lastKey: DynamoDbKey | null
): Observable<AdminListValveEventsResponse<ValveOfflineDiagnosticEvent>> {
  return apiPost<AdminListValveEventsResponse<ValveOfflineDiagnosticEvent>>(
    'GaasBackend',
    `/admin/valves/list-events/${valveId}/offline`,
    { lastKey }
  );
}

export function getValveSuspiciousValueEvents(
  valveId: string,
  lastKey: DynamoDbKey | null
): Observable<AdminListValveEventsResponse<SuspiciousValueDiagnosticEvent>> {
  return apiPost<AdminListValveEventsResponse<SuspiciousValueDiagnosticEvent>>(
    'GaasBackend',
    `/admin/valves/list-events/${valveId}/suspicious`,
    { lastKey }
  );
}

export function getThresholdPassedEvents(
  valveId: string,
  lastKey: DynamoDbKey | null
): Observable<AdminListValveEventsResponse<ThresholdPassedEventAdminView>> {
  return apiPost<AdminListValveEventsResponse<ThresholdPassedEventAdminView>>(
    'GaasBackend',
    `/admin/valves/list-events/${valveId}/threshold`,
    { lastKey }
  );
}

export function updateValveName(
  deviceIdentifier: string,
  valveName: string | null
): Observable<{}> {
  return apiPost<{}>(
    'GaasBackend',
    `/valves/${deviceIdentifier}/location`,
    {},
    { location: valveName }
  );
}

export function updateValveNote(
  id: string,
  note: string | null
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/admin/valves/update-note/${id}`, {
    note,
  });
}

export function updateGatewayNote(
  id: string,
  note: string | null
): Observable<{}> {
  return apiPost<{}>('GaasBackend', `/admin/gateway/update-note/${id}`, {
    note,
  });
}

export enum ValveHistoryBucket {
  H24,
  D7,
  D30,
}

function bucketToBucketString(bucket: ValveHistoryBucket): string {
  let bucketStr = '';
  switch (bucket) {
    case ValveHistoryBucket.D7:
      bucketStr = 'd7';
      break;
    case ValveHistoryBucket.H24:
      bucketStr = 'h24';
      break;
    case ValveHistoryBucket.D30:
      bucketStr = 'd30';
      break;
    default:
      throw Error('Unknown history bucket');
  }

  return bucketStr;
}

export function getValveHistory(
  serialNumber: string,
  bucket: ValveHistoryBucket
): Observable<ApiValveHistoryItem[]> {
  const bucketStr = bucketToBucketString(bucket);

  return apiGet<ApiValveHistoryItem[]>(
    'GaasBackend',
    `/valves/${serialNumber}/history/${bucketStr}`,
    {}
  );
}

export function getValveHistoryExport(
  serialNumber: string,
  bucket: ValveHistoryBucket
): Observable<{ csv: string }> {
  const bucketStr = bucketToBucketString(bucket);

  return apiGet<{ csv: string }>(
    'GaasBackend',
    `/valves/${serialNumber}/history/${bucketStr}/export`,
    {}
  );
}

export function pairContainerToValve(
  serialNumber: string,
  containerBarcode: string,
  pairingType: ApiContainerValvePairingType
): Observable<ApiContainerValvePairing> {
  return apiPost<ApiContainerValvePairing>(
    'GaasBackend',
    `/valves/${serialNumber}/container`,
    { barcode: containerBarcode, pairingType }
  );
}

export function unpairContainerFromValve(
  deviceIdentifier: string
): Observable<{}> {
  return apiDelete<{}>('GaasBackend', `/valves/${deviceIdentifier}/container`);
}

export function getValveAssignmentResponse(
  serialNumber: string
): Observable<ApiGetValveAssignmentResponse> {
  return apiGet<ApiGetValveAssignmentResponse>(
    'GaasBackend',
    `/valves/${serialNumber}/assignment`
  );
}

export function changeValveGroupAssignment(
  valveId: string,
  groupId: string | undefined
): Observable<ApiValveGroupAssignment> {
  return apiPut<ApiValveGroupAssignment>(
    'GaasBackend',
    `/valves/by-id/${valveId}/group`,
    { groupId: groupId ?? null }
  );
}
