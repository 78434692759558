import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { ManometerLoading } from '../../../stories/ManometerLoading';
import { TextButton } from '../../../stories/TextButton';
import { Replay } from '../../../stories/icons';
import CustomerSelection from '../../common/components/CustomerSelection';
import * as commonSelectors from '../../common/selectors';
import * as digidrumiActions from '../actions';
import DigiDrumiCard from '../components/DigiDrumiCard';
import * as digidrumiSelectors from '../selectors';
import styles from './DigiDrumiPage.module.scss';
import { ManometerCardDummy } from '../../../stories/ManometerCardDummy';
import DigiDrumiGroupAccordion from '../components/DigiDrumiGroupAccordion';
import { ApiValveState } from '../../../models/openapi/openapiTypes';
import * as groupActions from '../../groups/actions';
import * as groupSelectors from '../../groups/selectors';

const NO_GROUP_MARKER = 'NONE';

const lexicalSortValves: (
  deviceA: ApiValveState,
  deviceB: ApiValveState
) => number = (
  { deviceName: a, deviceIdentifier: aId },
  { deviceName: b, deviceIdentifier: bId }
) => {
  a = a ?? aId;
  b = b ?? bId;
  return a < b ? -1 : a > b ? 1 : 0;
};

const mapStateToProps = (state: RootState) => ({
  valves: digidrumiSelectors.valves(state.digidrumi),
  selectedCustomer: commonSelectors.selectedCustomer(state.common),
  thresholds: digidrumiSelectors.thresholds(state.digidrumi),
  customerGroups: (customerId: string) =>
    groupSelectors.customerGroups(state.groups, customerId),
});

const dispatchProps = {
  getValves: digidrumiActions.getValvesAsync.request,
  getThresholds: digidrumiActions.getThresholdAsync.request,
  getCustomerGroups: groupActions.getCustomerGroupsAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const DigiDrumi: React.FC<Props> = ({
  valves,
  thresholds,
  selectedCustomer,
  getValves,
  getThresholds,
  getCustomerGroups,
  customerGroups,
}) => {
  const customerId = selectedCustomer?.customerId;
  useEffect(() => {
    if (valves.error || valves.loading) {
      return;
    }
    if (!valves.data && customerId) {
      getValves(customerId);
    }
  }, [valves.error, valves.loading, valves.data, customerId, getValves]);

  useEffect(() => {
    if (thresholds.error || thresholds.loading) {
      return;
    }
    if (!thresholds.data && customerId) {
      getThresholds(customerId);
    }
  }, [
    thresholds.error,
    thresholds.loading,
    thresholds.data,
    customerId,
    getThresholds,
  ]);

  const groupsForThisCustomer = customerGroups(customerId ?? '');
  useEffect(() => {
    if (groupsForThisCustomer.error || groupsForThisCustomer.loading) {
      return;
    }
    if (!groupsForThisCustomer.data && customerId) {
      getCustomerGroups({ customerId });
    }
  }, [
    customerId,
    groupsForThisCustomer.data,
    groupsForThisCustomer.error,
    groupsForThisCustomer.loading,
    getCustomerGroups,
  ]);

  const loadValves = () => {
    if (selectedCustomer && !valves.loading) {
      getValves(selectedCustomer.customerId);
    }
  };

  const valveContent = () => {
    if (valves.error) {
      return (
        <div className={styles['gaas-manometer-page--empty']}>
          <p>Laden der Ventilinformationen fehlgeschlagen.</p>
          <TextButton
            label="Erneut versuchen"
            LeadingIcon={Replay}
            onClick={() => loadValves()}
          />
        </div>
      );
    }

    if (valves.loading) {
      return (
        <div className={styles['gaas-manometer-page--empty']}>
          <ManometerLoading />
        </div>
      );
    }

    const valvesData = valves.data ?? [];
    const thresholdData = thresholds.data ?? [];
    if (!valvesData.length) {
      return <ManometerCardDummy />;
    }

    const valvesGroupedByGroup = valvesData.reduce<
      Record<string, ApiValveState[]>
    >((acc, val) => {
      const groupIdOfValve = val.groupId ?? NO_GROUP_MARKER;
      if (acc[groupIdOfValve] === undefined) {
        acc[groupIdOfValve] = [];
      }

      acc[groupIdOfValve].push(val);
      return acc;
    }, {});

    if (Object.keys(valvesGroupedByGroup).length <= 1) {
      return (
        <div className={styles['gaas-manometer-page']} role={'list'}>
          {valvesData.map(valve => (
            <DigiDrumiCard
              valve={valve}
              key={valve.deviceIdentifier}
              thresholds={
                thresholdData?.filter(
                  it => it.deviceId === valve.deviceIdentifier
                ) ?? []
              }
              groups={groupsForThisCustomer.data ?? []}
            />
          ))}
        </div>
      );
    }

    for (const key in valvesGroupedByGroup) {
      valvesGroupedByGroup[key].sort(lexicalSortValves);
    }

    return (
      <div>
        {Object.values(valvesGroupedByGroup).map(valvesOfAGroup => (
          <DigiDrumiGroupAccordion
            groupId={valvesOfAGroup[0].groupId ?? NO_GROUP_MARKER}
            groupName={
              valvesOfAGroup[0].groupId
                ? groupsForThisCustomer.data?.find(
                    it => it.id === valvesOfAGroup[0].groupId
                  )?.name ?? `${valvesOfAGroup[0].groupId}`
                : 'Ohne Gruppe'
            }
            customerId={customerId ?? NO_GROUP_MARKER}
            key={valvesOfAGroup[0].groupId ?? NO_GROUP_MARKER}
          >
            <div className={styles['gaas-manometer-page']} role={'list'}>
              {valvesOfAGroup.map(valve => (
                <DigiDrumiCard
                  valve={valve}
                  key={valve.deviceIdentifier}
                  thresholds={
                    thresholdData?.filter(
                      it => it.deviceId === valve.deviceIdentifier
                    ) ?? []
                  }
                  groups={groupsForThisCustomer.data ?? []}
                />
              ))}
            </div>
          </DigiDrumiGroupAccordion>
        ))}
      </div>
    );
  };

  return (
    <CustomerSelection title="Digitale Manometer" showGatewayIndicator>
      {valveContent()}
    </CustomerSelection>
  );
};

export default connect(mapStateToProps, dispatchProps)(DigiDrumi);
